.form-control.is-invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-link.active {
  background-color: rgb(91, 91, 223);
  color: white;
  border-color: rgb(91, 91, 223);
}


.audio_player_style.rs-modal-sm {
  width: 720px !important
}

.audio_player_modal {
  padding: 10px;
  padding-bottom: 0;
  width: 100%;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* High z-index to ensure it appears on top */
  width: 300px;
  /* Width for the popup */
  height: 400px;
  /* Increased height to ensure space for the list and button */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

.popup {
  background-color: #fff;
  /* White background */
  border-radius: 15px;
  /* Border radius for rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for depth */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Space between the content and the button */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  /* Enable vertical scroll if content overflows */
  max-height: calc(100% - 60px);
  /* Ensure the list area fits within the popup */
}

.popup li {
  padding: 10px;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.popup li:hover {
  background-color: #f0f0f0;
}

.move-button {
  background-color: #4256b3;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s, opacity 0.3s;
}

.move-button:hover {
  background-color: #314493;
}

.move-button.disabled {
  background-color: #c3c3c3;
  /* Light grey when disabled */
  cursor: not-allowed;
  opacity: 0.6;
}

input[type="radio"] {
  margin-right: 10px;
}


.btn__upload__file_modal {
  border-radius: 110px;
  background: #FFAB49;
  box-shadow: 0px 1.1px 2.2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'Inter';

}

.file_upload_modal_style .rs-modal-body {
  padding: 40px !important;
}

.file_upload_modal_style .rs-modal-body .radio_checkbox_list {
  margin-top: 15px;
}

.close-button {
  position: absolute;
  top: -7px !important;
  right: 3px !important;
  background: none;
  border: none;
  font-size: 25px !important;
  cursor: pointer;
  color: #fc424a !important;
}

.popup-container {
  border-radius: 15px;
}


.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
  height: 48px;
}

.logo_login img {
  max-height: 76px;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  /* Light gray */
  border-radius: 8px;
  height: 10px;
  margin-top: 10px;
}

.progress-bar {
  background-color: #007bff;
  /* Blue color */
  height: 100%;
  border-radius: 8px;
  transition: width 0.3s ease;
  /* Smooth transition */
}

.progress {
  background-color: #d8d8d8;
  /* Light gray for the progress element */
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 100%;
  /* Full width of the container */
}

.card-6-inner {
  padding: 1rem;
}

.card-6-inner label {
  display: block;
}

/* Optional: If you want a gradient for the progress done effect */
.progress-done {
  background: linear-gradient(to left, #F2709C, #FF9472);
  box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}